import '@/styles/globals.css'
import '@mantine/core/styles.css'

import { MantineProvider } from '@mantine/core'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'
import { MDXComponents, MergeComponents } from '@mdx-js/react/lib'
import { GoogleTagManager } from '@next/third-parties/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import { DefaultSeo } from 'next-seo'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { useEffect } from 'react'

import HeadMeta from '@/components/AppShellComponents/HeadMeta'
import InstallHook from '@/components/pwa/InstallHook'
import config from '@/config'
import { emotionCache } from '@/emotion/cache'
import { ProviderWrapper } from '@/provider/ProviderWrapper'
import SEOconfig from '@/seo.config'
import { theme } from '@/styles/theme'
import { initiClarity } from '@/utils/anEvents'

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ['latin'] })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: true,
            gcTime: 1000 * 60 * 60,
        },
    },
})

const components: MDXComponents | MergeComponents = {}

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        if (!process.env.NEXT_PUBLIC_ENV_DEV_MODE) {
            initiClarity(config.CLARITY_ID)
        }
    }, [])

    return (
        <>
            <MantineEmotionProvider cache={emotionCache}>
                <MantineProvider
                    defaultColorScheme='dark'
                    theme={theme}
                    stylesTransform={emotionTransform}
                >
                    {!process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
                        <>
                            <GoogleTagManager gtmId={config.GTM_ID} />
                            <GoogleAnalytics trackPageViews strategy='afterInteractive' />
                        </>
                    )}

                    <DefaultSeo {...SEOconfig} />
                    <InstallHook />
                    <HeadMeta />
                    <main className={inter.className}>
                        <QueryClientProvider client={queryClient}>
                            <ProviderWrapper>
                                <Component {...pageProps} />
                            </ProviderWrapper>
                            {process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
                                <ReactQueryDevtools initialIsOpen />
                            )}
                        </QueryClientProvider>
                    </main>
                </MantineProvider>
            </MantineEmotionProvider>
        </>
    )
}
